<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="60%"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="90px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="数据名称:" prop="dictionaryName">
            <!-- <el-input :disabled="disableChange" maxlength="10" show-word-limit v-model="form.dictionaryName"></el-input> -->
            <el-input maxlength="10" placeholder="请输入数据名称" show-word-limit v-model="form.dictionaryName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注:">
            <el-input v-model="form.remark" placeholder="请输入备注信息"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-if="isShow">
          <el-form-item label="主标题:">
            <el-input v-model="form.positiveTitle" placeholder="请输入主标题"></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="isShow" :span="12">
          <el-form-item label="副标题:">
            <el-input v-model="form.subTitle" placeholder="请输入副标题"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col v-if="isShow">
          <el-form-item label="图标:">
            <el-upload class="" :action="action" :show-file-list="false" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess">
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col v-if="isShow">
          <el-form-item label="权益详情:">
            <!-- <quill-editor :options="editorOption" v-model="form.text"> </quill-editor> -->
            <fuwenben ref="editor"
              v-model="form.text"
              @onClick="onClick">
            </fuwenben>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col v-if="isShowCheck" :span="12">
          <el-form-item label="选中图标:">
            <el-upload class="" :action="action" :show-file-list="false" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccessCheck">
              <img v-if="checkImageUrl" :src="checkImageUrl" class="avatar" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="isShowCheck">
          <el-form-item label="未选中图标:">
            <el-upload class="" :action="action" :show-file-list="false" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccessCheckUncheck">
              <img v-if="UnCheckimageUrl" :src="UnCheckimageUrl" class="avatar" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureSubmit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequest } from 'src/api/index'
import { imgaction } from 'src/utils/cities'
import E from 'wangeditor'
import fuwenben from '@/views/infomationManagement/components/fuwenben'
export default {
  data() {
    return {
      dialogVisible: true,
      form: {
        dictionaryCategoryName: '',
        remark: '',
        iconUrl: '',
        text: '',
        positiveTitle: '',
        subTitle: '',
      },
      title: '',
      disableChange: false,
      url: '',
      imageUrl: '',
      checkImageUrl: '',
      UnCheckimageUrl: '',
      isShowCheck: false,
      isShow: false,
      action: imgaction,
      editorOption: {
        placeholder: '请输入正文',
      },
      rules: {
        dictionaryName: [{ required: true, message: '请输入数据名称', trigger: 'blur' }],
      },
    }
  },
  props: ['info', 'bigKey', 'bigName'],
  components: {fuwenben},
  mounted() {
    console.log(this.info, this.bigKey, this.bigName, 'dialogInfo')
    if (this.bigKey == '05') {
      this.isShow = true
    }
    if (this.bigKey == '12') {
      this.isShowCheck = true
    }
    if (this.info != '' && this.info.dictionaryName) {
      this.disableChange = true
    }
    if (this.info != '') {
      this.form = this.info
      this.imageUrl = this.info.iconUrl
      this.checkImageUrl = this.info.checkedUrl
      this.UnCheckimageUrl = this.info.uncheckedUrl
      this.title = '修改'
    } else {
      this.title = '新增'
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeModify')
    },
    handleAvatarSuccess(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success',
        })
        this.form.iconUrl = res.data
        this.imageUrl = URL.createObjectURL(file.raw)
      }
    },
    handleAvatarSuccessCheck(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success',
        })
        this.form.checkedUrl = res.data
        this.checkImageUrl = URL.createObjectURL(file.raw)
      }
    },
    beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('上传图标大小不能超过 2MB!');
        }
        return isLt2M;
      },
    handleAvatarSuccessCheckUncheck(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success',
        })
        this.form.uncheckedUrl = res.data
        this.UnCheckimageUrl = URL.createObjectURL(file.raw)
      }
    },
    onClick (e, editor) {
      console.log('Element clicked')
      console.log(e)
      console.log(editor)
    },
    // 提交修改和新增
    sureSubmit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          let data = {}
          if (this.info != '') {
            this.form.dictionaryId = this.info.dictionaryId
            ;(this.form.dictionaryCategoryCode = this.bigKey),
              (this.form.dictionaryCategoryName = this.bigName),
              postRequest('/dict/updateDictVo/', this.form).then(res => {
                console.log(res)
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.handleClose()
              })
          } else {
            ;(this.form.dictionaryCategoryCode = this.bigKey),
              (this.form.dictionaryCategoryName = this.bigName),
              postRequest('/dict/saveDictType/', this.form).then(res => {
                console.log(res)
                this.$message({
                  message: '添加成功',
                  type: 'success',
                })
                this.handleClose()
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
img {
  width: 100%;
}

.ql-container {
  height: 200px !important;
}
.ql-editor {
  height: 200px !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  border: 1px solid #409EFF;
  border-radius: 5px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>