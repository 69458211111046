<template>
  <div class="main">
    <div class="left">
      <div class="top">
        <div class="typeName">类型名称</div>
      </div>
      <el-menu
        :default-active="key"
        class="el-menu-vertical-demo"
        @select="handleSelect">
        <el-menu-item v-for="(item, index) in result" :key="index" :index="item.dictionaryCategoryCode">
          <span slot="title">{{item.dictionaryCategoryName}}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <!-- <div class="middle"></div> -->
    <div class="right">
      <div class="button" style="">
        <div style="display: flex;">
          <el-input class="inputBox" v-model="text" placeholder="数据名称"></el-input><el-button type="primary" :disabled="disabledSearch" @click="getSmallClass(1)" icon="el-icon-search">查询</el-button>
        </div>
        <el-button type="primary" v-show="isShow" plain icon="el-icon-plus" @click="handleClick('')">添加</el-button>
      </div>
      <el-table :data="tableData" v-loading="loading" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#999'}" :cell-style="{'text-align':'center'}" style="width: 100%">
        <el-table-column fixed prop="dictionaryName" label="数据名称"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="state" label="状态"> </el-table-column>
        <el-table-column prop="createTime" label="设置时间"> </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
        <el-table-column fixed="right" v-if="isLook" label="操作" width="130">
          <template slot-scope="scope">
            <el-button v-if="scope.row.stuts == '1'" @click="handleUse(scope.row, 0)" type="text" size="small">禁用</el-button>
            <el-button v-if="scope.row.stuts == '0'" @click="handleUse(scope.row, 1)" type="text" size="small">启用</el-button>
            <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button>
            <!-- <el-button @click="handleDelete(scope.row, 1)" class="deletebutton" type="text" size="small">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <modify :bigKey="key" :bigName="bigName" :info="info" @closeModify="closeModify" v-if="showModify"></modify>
  </div>
</template>

<script>
import modify from '@/views/basisSet/component/modify'
import { postRequest } from 'src/api/index'
import { formatTime } from '@/utils/utils'
export default {
  data() {
    return {
      activeNames: ['1'],
      result:[],
      formInline:{
        user: '',
        region: ''
      },
      tableData: [],
      text: '',
      showModify: false,
      info: '',
      bigName: '',
      key: '',
      total: null,
      pageNo: 1,
      pageSize: 10,
      isShow: false,
      isLook: false,
      loading: true,
      disabledSearch: false,
    }
  },
  components: { modify },
  mounted () {
    //  查询大类
    postRequest('/dict/getCategoryType/').then(res=>{
      console.log(res,'大类')
      this.result = res
      this.key = this.result[0].dictionaryCategoryCode
      this.bigName = this.result[0].dictionaryCategoryName
      // let data = {
      //   dictionaryCategoryCode: this.result[0].dictionaryCategoryCode
      // }
      this.getSmallClass()
    })
  },
  // computed: {
  //   isLook () {
  //     return this.key != '01' || this.key != '03' || this.key != '06' || this.key != '07'
  //   }
  // },
  methods: {
    handleChange(val) {
      console.log(val)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getSmallClass()
    },
    // searchResult(){
    //   console.log(this.text)
    //   let data = {
    //     dictionaryName: this.text,
    //     dictionaryCategoryCode: this.key,
    //     P
    //   }
    //   postRequest('/dict/getDictByCategoryPage/',data).then(res => {
    //     console.log(res)
    //   })
    // },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getSmallClass()
    },
    handleClick(row) {
      console.log(row)
      this.info = row
      this.showModify = true
    },
    handleUse(val, index){
      console.log(val)
      let data = {
        dictionaryId: val.dictionaryId,
        stuts: index
      }
      postRequest('/dict/updateDictVo/', data).then(res=> {
        console.log(res)
        this.getSmallClass()
      })
    },
    handleDelete(val, ind){
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          let data = {
            dictionaryId: val.dictionaryId,
            isDelete: "0"
          }
          this.delAndUpdata(data, ind)
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          });
        });
      console.log(val)
      
    },
    handleSelect (key, keyPath) {
      this.text = ''
      if (key == '24' || key == '07' || key == '06' || key == '12' || key == '04' || key == '01' || key == '03' || key == '09' || key == '13' || key == '14' || key == '20') {
        this.isShow = false
      } else {
        this.isShow = true
      }
      if (key == '01' || key == '03' || key == '06' || key == '07' || key == '09' || key == '24' || key == '13' || key == '14' || key == '04') {
        this.isLook = false
      } else {
        this.isLook = true
      }
      console.log(key, keyPath)
      this.key = key
      this.result.map(item => {
        if (item.dictionaryCategoryCode == this.key) {
          this.bigName = item.dictionaryCategoryName
        }
      })
      // let data = {
      //   dictionaryCategoryCode: this.key
      // }
      this.getSmallClass()
    },
    changeShowForm(){
      this.showModify = true
    },
    closeModify(){
      this.showModify = false
      // let data = {
      //   dictionaryCategoryCode: this.key
      // }
      this.getSmallClass()
    },
    // 删除及修改
    delAndUpdata(data,ind) {
      postRequest('/dict/deleteDictVo/',data).then(res=>{
        console.log(res)
        if (ind == 1) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        }
        // let data1 = {
        //   dictionaryCategoryCode: this.key
        // }
        this.getSmallClass()
      })
    },
    // 查询小类
    getSmallClass(no){
      let _this = this
      this.disabledSearch = true
      this.loading = true
      let data = {
        dictionaryCategoryCode: this.key,
        dictionaryName: this.text,
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize
      }
      postRequest('/dict/getDictByCategoryPage/', data).then(res=>{
        this.loading = false
        this.disabledSearch = false
        res.data.map(item=>{
          if(typeof item.updateTime === "string"){
            item.updateTime = item.updateTime ? item.updateTime.substring(0,10) : ''
          item.createTime = item.createTime ? item.createTime.substring(0,10) : ''
          } else{
            item.updateTime = item.updateTime ? formatTime(new Date(item.updateTime)): ''
            item.createTime = item.createTime ? formatTime(new Date( item.createTime)) : ''
          }
          if (item.stuts == '1') {
            item.state = '启用'
          } else if (item.stuts == '0') {
            item.state = '禁用'
          }
        })
        _this.total = res.count
        _this.tableData = res.data
        console.log('_this.table', _this.tableData)
      }).catch((err)=>{
        console.log('err', err)
        this.loading = false
        this.disabledSearch = false
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  padding: 21px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 /11%) !important;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.el-collapse {
  width: 100%;
}
.typeName {
  text-align: center;
}
.button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 32px;
}
.is-active {
  background: #EEF1FC !important;
  color: #333 !important;
}
.left {
  width: 210px;
}
.right {
  flex:1;
  padding-left:20px;
}
.el-menu-item{
  height:43px;
  line-height:43px;
  margin-right:40px;
  // color: #999;
}
</style>
